import { useContext } from "react"

// Navigation
import { matchPath, useLocation } from "@/lib/router"
import { Routes } from "@/constants/routes"
import { Link } from "@/components/Anchor"

// Translation
import { useTrans } from "@/i18n"

// UI
import { classNames } from "@/lib/ui"

// Context
import { AuthLayoutContext } from "@/layouts/AuthLayout"
import { useInvestmentTests } from "@/context/investmentTests"
import { useCurrentUserMessagesCount } from "@/context/user"

/**
 * ProfileSubMenu
 * @returns
 */
export function ProfileSubMenu() {
	// Router
	const location = useLocation()

	// Context
	const { setMobileMenuOpen } = useContext(AuthLayoutContext)
	const { amountOfRequiredActionsLeft } = useInvestmentTests()
	const messagesCount = useCurrentUserMessagesCount()

	/**
	 * Test if location path is any child page of the investor profile
	 * @returns
	 */
	function isInvestorProfileSpecificSubPath() {
		// Return true if it is a full match
		if (location.pathname === Routes.InvestorProfile) {
			return true
		}

		// Check if the current path is a subpath of the ProjectsDashboard page
		const matchesPath = matchPath(
			{
				path: "/investor/:tab",
				end: true,
			},
			location.pathname,
		)
		return matchesPath !== null
	}

	const t = useTrans(["common", "investments"])

	function closeMenu() {
		setMobileMenuOpen(false)
	}

	return (
		<nav className="space-y-1 pr-3">
			{/** Personal data */}
			<Link
				href={Routes.SettingsProfilePersonalDetails}
				className={({ isActive }) =>
					classNames(
						isActive && "active-navigation-item",
						"block truncate text-sm hover:text-gray-500",
					)
				}
				onClick={closeMenu}
			>
				{t("common.layout.quick_menu.your_profile")}
			</Link>

			{/** Communication hub */}
			{messagesCount > 0 && (
				<Link
					className={({ isActive }) =>
						classNames(
							isActive && "active-navigation-item",
							"block truncate text-sm hover:text-gray-500",
						)
					}
					href={Routes.Communications}
					onClick={closeMenu}
				>
					{t("common:common.nav.all_messages")}
				</Link>
			)}

			{/** Investor profile */}
			<Link
				className="flex w-full items-center focus:no-underline"
				href={Routes.InvestorProfile}
				onClick={closeMenu}
			>
				<>
					<span
						className={classNames(
							isInvestorProfileSpecificSubPath() &&
								"active-navigation-item",
							"truncate text-sm hover:text-gray-500",
						)}
					>
						{t("common:common.navigation.investor-profile.title")}
					</span>

					{/** Show badge with number */}
					{amountOfRequiredActionsLeft !== undefined &&
						amountOfRequiredActionsLeft !== 0 && (
							<span className="ml-1 block h-4 w-4 items-center rounded-full bg-red-600 text-center text-xs text-white">
								{" "}
								{amountOfRequiredActionsLeft}
							</span>
						)}
				</>
			</Link>
		</nav>
	)
}
